import { makeStyles } from '@material-ui/core';
import { Body, utils } from '@jobdone/jobdone-web-design-system';
import StaggerParent from '@common/animations/StaggerParent';
import FadeInOut from '@common/animations/FadeInOut';
import FadeInUp from '@common/animations/FadeInUp';
import { useTranslation } from 'next-i18next';
import LangChanger from './LangChanger';

const { JobDoneLocales } = utils;

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    justifyContent: 'center',
    gap: 32,
    marginBottom: 10,
    width: '100%',
    [theme.breakpoints.down('sm')]: {
      gap: 14,
      flexDirection: 'column',
    },
    '@media (max-width:340px)': {
      gap: 5,
      flexDirection: 'column',
    },
  },
  flexContainer: {
    display: 'flex',
    justifyContent: 'center',
    gap: 24,
    '@media (max-width:340px)': {
      gap: 0,
      margin: '0 20px',
    },
  },
  link: {
    transition: 'all .2s ease',
    color: theme.palette.grey[700],
    textDecoration: 'none',
    '&:hover': {
      color: '#4589ff',
      textDecoration: 'underline',
    },
    '&:active': {
      color: '#3467bf',
    },
  },
}));

const PageFooterWithTermsAndLangChange = () => {
  const styles = useStyles();
  const { t, i18n } = useTranslation();

  const getTranslatedHref = () => {
    switch (i18n.language) {
      case JobDoneLocales.ENGLISH:
        return {
          contactUs: 'https://jobdone.app/en/support/',
          privacyAndPolicy: 'https://jobdone.app/en/privacy/',
          termsAndConditions: 'https://jobdone.app/en/general-terms-and-conditions/',
        };
      case JobDoneLocales.GERMAN:
        return {
          contactUs: 'https://jobdone.app/de/support/',
          privacyAndPolicy: 'https://jobdone.app/privatsphaere/',
          termsAndConditions: 'https://jobdone.app/allgemeine-geschaeftsbedingungen/',
        };

      default:
        return {
          contactUs: 'https://jobdone.app/en/support/',
          privacyAndPolicy: 'https://jobdone.app/en/privacy/',
          termsAndConditions: 'https://jobdone.app/en/general-terms-and-conditions/',
        };
    }
  };

  return (
    <FadeInOut>
      <StaggerParent delay={0.05}>
        <div className={styles.root}>
          <div className={styles.flexContainer}>
            <FadeInUp>
              <Body
                component='span'
                variant='small'
                display='inline'
              >
                <a
                  className={styles.link}
                  target='_blank'
                  href={getTranslatedHref().termsAndConditions}
                  rel='noreferrer'
                >
                  {t('register:policy.terms')}
                </a>
              </Body>
            </FadeInUp>
            <FadeInUp>
              <Body
                component='span'
                variant='small'
                display='inline'
              >
                <a
                  className={styles.link}
                  target='_blank'
                  href={getTranslatedHref().privacyAndPolicy}
                  rel='noreferrer'
                >
                  {t('register:policy.privacy')}
                </a>
              </Body>
            </FadeInUp>
            <FadeInUp>
              <Body
                component='span'
                variant='small'
                display='inline'
              >
                <a
                  className={styles.link}
                  target='_blank'
                  href={getTranslatedHref().contactUs}
                  rel='noreferrer'
                >
                  {t('register:contact_us')}
                </a>
              </Body>
            </FadeInUp>
          </div>
          <div className={styles.flexContainer}>
            <FadeInUp>
              <LangChanger />
            </FadeInUp>
          </div>
        </div>
      </StaggerParent>
    </FadeInOut>
  );
};

export default PageFooterWithTermsAndLangChange;
