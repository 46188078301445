import Joi from 'joi';
import { validationMessages } from './constants/validationMessages';
import {
  letterNumbersSpecialChars,
  isFirstCharacterSpace,
  onlyContainsSpecialCharacters,
  passwordRegex,
  letterNumbersSpecialCharsWithPercentageSymbol,
} from './utils/regex';

const rawNameSchema = Joi.string().max(128)
  .custom((value, helpers) => {
    if (isFirstCharacterSpace(value)) {
      return helpers.error('any.firstCharSpace');
    }
    if (onlyContainsSpecialCharacters(value)) {
      return helpers.error('any.onlyContainSpecialCharacters');
    }
    return value;
  })
  .messages({
    'string.empty': validationMessages.FIELD_REQUIRED,
    'any.required': validationMessages.FIELD_REQUIRED,
    'string.pattern.base': validationMessages.INVALID_ALPHANUMERIC_WITH_SPECIAL_SYMBOLS,
    'any.firstCharSpace': validationMessages.FIRST_CHAR_IS_SPACE,
    'any.onlyContainSpecialCharacters': validationMessages.ONLY_CONTAINS_SPECIAL_CHARS,
    'string.max': validationMessages.MAX_LENGTH,
  });

export const name = rawNameSchema.regex(letterNumbersSpecialChars).required();
export const nameWithPercentage = rawNameSchema.regex(letterNumbersSpecialCharsWithPercentageSymbol).required();

export const nameOptional = Joi.string().allow('').max(128).regex(letterNumbersSpecialChars)
  .custom((value, helpers) => {
    if (isFirstCharacterSpace(value)) {
      return helpers.error('any.firstCharSpace');
    }
    if (onlyContainsSpecialCharacters(value)) {
      return helpers.error('any.onlyContainSpecialCharacters');
    }
    return value;
  })
  .messages({
    'string.empty': validationMessages.FIELD_REQUIRED,
    'any.required': validationMessages.FIELD_REQUIRED,
    'string.pattern.base': validationMessages.INVALID_ALPHANUMERIC_WITH_SPECIAL_SYMBOLS,
    'any.firstCharSpace': validationMessages.FIRST_CHAR_IS_SPACE,
    'any.onlyContainSpecialCharacters': validationMessages.ONLY_CONTAINS_SPECIAL_CHARS,
    'string.max': validationMessages.MAX_LENGTH,
  });

export const email = Joi.string().email({ tlds: { allow: false } }).messages({
  'string.email': validationMessages.INVALID_EMAIL,
  'any.required': validationMessages.FIELD_REQUIRED,
  'string.empty': validationMessages.FIELD_REQUIRED,
});
export const emailOptional = email.allow('');
export const password = Joi.string().regex(passwordRegex);
export const textArea = Joi.string().custom(((value, helpers) => {
  if (value.length > 200) {
    return helpers.message({ custom: `${String(value.length)}/200` });
  }
  return value;
}));
export const textAreaOptional = textArea.allow('');

export const passwordWithSeperateRules = Joi
  .string()
  .required()
  .min(8)
  .custom((value, helpers) => {
    if (!/\d/.test(value?.trim())) {
      return helpers.error('any.shouldHaveAtLeastOneDigit');
    }
    if (!/[A-Z]/.test(value?.trim())) {
      return helpers.error('any.shouldHaveAtLeastOneUppercaseChar');
    }
    if (!/[a-z]/.test(value?.trim())) {
      return helpers.error('any.shouldHaveAtLeastOneLowercaseChar');
    }
    return value;
  })
  .messages({
    'string.empty': validationMessages.FIELD_REQUIRED,
    'any.required': validationMessages.FIELD_REQUIRED,
    'string.min': validationMessages.MIN_LENGTH,
    'any.shouldHaveAtLeastOneDigit': validationMessages.SHOULD_HAVE_AT_LEAST_ONE_DIGIT,
    'any.shouldHaveAtLeastOneUppercaseChar': validationMessages.SHOULD_HAVE_AT_LEAST_ONE_UPPERCASE_CHAR,
    'any.shouldHaveAtLeastOneLowercaseChar': validationMessages.SHOULD_HAVE_AT_LEAST_ONE_LOWERCASE_CHAR,
  });
